import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Blocks from '../components/blocks';
import Navigation from '../components/navigation';
import Header from '../components/header';
import Footer from '../components/footer';
import { SocialBar, SocialFooterBar } from '../components/social';

import logoBe from '../images/be-hospitaalbroeders-logo.svg';

export const query = graphql`
  query LandingPageBelgiumTemplateQuery($id: String!) {
    site {
      siteMetadata {
        title
        description
        author
      }
    }

    page: contentfulLandingPageBelgium(id: { eq: $id }) {
      id
      title
      slug
      blocks: contentBlocks {
        type: __typename
        ...BlockCopyFields
        ...BlockHeroFields
      }
    }

    navigation: contentfulNavigation(
      title: { regex: "/^Belgium > Header > Navigation/" }
    ) {
      id
      title
      items: navigationItems {
        id
        title
        pageLink {
          ... on ContentfulLandingPage {
            slug
          }
          ... on ContentfulLandingPageBelgium {
            slug
          }
          ... on ContentfulCampaignPage {
            slug
          }
        }
        items: navigationItems {
          id
          title
          pageLink {
            ... on ContentfulLandingPage {
              slug
            }
            ... on ContentfulLandingPageBelgium {
              slug
            }
            ... on ContentfulCampaignPage {
              slug
            }
          }
        }
      }
    }

    footer: contentfulFooter(
      title: { regex: "/^Belgium > Landing Page > Footer/" }
    ) {
      id
      title
      blocks: contentBlocks {
        type: __typename
        ...BlockCopyFields
        ...BlockMailingListFields
      }
    }
  }
`;

const StyledNavigation = styled(Navigation)``;

const LandingPageBelgiumTemplate = ({ data }) => {
  const { site, page, navigation, footer } = data;
  const { title, slug, blocks } = page;

  const currentPath =
    typeof window !== 'undefined' ? window.location.pathname : '';

  const pageHeader = (
    <Header siteTitle={site.siteMetadata.title} logo={logoBe} logoLink="/be">
      <SEO title={title} slug={slug} />
      <StyledNavigation items={navigation.items} />
    </Header>
  );

  const pageFooter = (
    <Footer renderDonateCta={false}>
      <Blocks blocks={footer.blocks || []} />
    </Footer>
  );

  return (
    <Layout header={pageHeader} footer={pageFooter}>
      {/* <SocialBar /> */}
      <Blocks blocks={blocks || []} />
      {/* <SocialFooterBar /> */}
    </Layout>
  );
};

LandingPageBelgiumTemplate.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape.isRequired,
    page: PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired
        })
      )
    }),
    navigation: PropTypes.shape.isRequired,
    footer: PropTypes.shape({
      title: PropTypes.string.isRequired,
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired
        })
      )
    })
  }).isRequired
};

export default LandingPageBelgiumTemplate;
